.herosection {
  position: relative;
  padding: 2rem var(--body-side-paddings) !important;
  height: initial;
  min-height: 90vh;
}

.herosection__bgimage {
  width: 100%;
  height: 100%;
  background-image: url(https://res.cloudinary.com/learnhat/image/upload/v1632228593/website_images/hero_Section_img2.jpg);
  background-position: 50% 20%;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0vh;
  left: 0;
  z-index: -2;
}

.herosection__content {
  display: flex;
  height: 100%;
  justify-content: space-between;
}

.herosection__text {
  width: 55%;
  color: #fff;
  margin-left: 2rem;
}

.herosection__heading {
  font-size: 2rem;
  margin-top: 5rem;
  width: 70%;
}

.herosection__highlight {
  padding: 0.2rem 0.5rem;
  background-color: var(--red);
  margin: 0 0.5rem;
}

.herosection__description {
  width: 90%;
  margin-top: 1.2rem;
  font-size: 1.05rem;
  line-height: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.herosection__getapplink {
  margin-top: 8.5rem;
  display: flex;
  width: 100%;
  height: 150px;
  align-items: center;
}

.herosection__getapplink--nomt {
  margin-top: 0;
}

.herosection__getapplink--blue {
  margin-left: 3rem;
}

.herosection__getapplink--blue .herosection__getapplink-input > p {
  color: #fff;
}

.herosection__getapplink-input {
  color: #000;
  margin-left: 0rem;
  width: 100%;
}

.herosection__getapplink-input > p {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
}

.herosection__getapplink-inputcontainer input {
  width: 250px;
  margin-right: 0.5rem !important;
}

.herosection__getapplink-inputcontainer {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.herosection__getapplink-bottom {
  display: flex;
  align-items: center;
}

.herosection__formcontainer {
  width: 40%;
  height: 80% !important;
  border-radius: 0.5rem;
  box-shadow: 0px 10px 60px 0 rgba(0, 0, 0, 0.08);
  background-color: rgb(255, 255, 255);
  padding: 3rem;
}

/* .herosection__form {
  height: initial;
} */

.herosection__registerform > input,
.herosection__registerform > select {
  margin-bottom: 0.7rem;
  font-size: 1rem;
}

.herosection__formheading {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.herosection__categories {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.herosection__categories button {
  width: 50%;
  border-radius: 0;
}

@media screen and (max-width: 500px) {
  .herosection {
    padding: 0 !important;
    height: initial;
  }

  .herosection__bgimage {
    width: 100%;
    height: 75vh;
    background-position: 20% 20%;
  }

  .herosection__content {
    width: 100%;
    flex-direction: column;
    padding: 0 1rem;
  }

  .herosection__text {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .herosection__heading {
    font-size: 1.6rem;
    line-height: 2.5rem;
    width: 100%;
  }

  .herosection__brand {
    font-size: 3rem;
  }

  .herosection__description {
    width: 100%;
    font-size: 1rem;
  }

  .herosection__getapplink {
    width: 100%;
    flex-direction: column;
    height: initial;
    align-items: initial;
    margin-top: 5rem;
  }

  .herosection__getapplink--blue {
    margin-left: 0rem;
    margin-top: 0;
  }

  .herosection__getapplink-input {
    margin-left: 0;
    color: #fff;
    width: 100%;
  }

  .herosection__getapplink-input > p {
    width: 100% !important;
    text-align: center;
    font-size: 1rem;
  }
  
  .herosection__getapplink-bottom {
    width: 100%;
    flex-direction: column;
  }

  .herosection__getapplink-inputcontainer {
    width: 100%;
    align-items: center;
  }

  .herosection__formcontainer {
    width: 100%;
    padding: 1rem;
    margin-top: 1.5rem;
  }
}
