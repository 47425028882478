.btn {
  padding: 1rem 1rem;
  border-radius: 0.2rem;
  color: #fff;
  border: none;
  text-transform: uppercase;
  font-size: 0.85rem;
  letter-spacing: 1.5px;
  font-weight: 500;
  cursor: pointer;
  min-width: 150px;
  height: 50px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
}

.btn--blue {
  background-color: var(--brand-blue);
}

.btn--gray {
  background-color: var(--gray) !important;
}

.btn-purple {
  background-color: var(--base-purple);
}

.btn-yellow{
  color: #000;
  background-color: #ffba1b;
}

.btn--large {
  /* padding: 1.4rem 2.8rem !important; */
  /* font-size: 0.9rem; */
  /* font-weight: 700; */
}

.btn-iconwithtext {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  font-weight: 700;
  color: #000;
  font-size: 0.9rem;
  border: none;
  cursor: pointer;
  position: relative !important;
  height: 70px;
  padding: 0.5rem;
}

.btn-iconwithtext{
  height: 50px;
}

.btn-iconwithtext:hover {
  color: #000;
  font-weight: 700;
}

.btn-hover-purple:hover{
  background-color: var(--base-purple);
  color: #fff;
}

.btnicon {
  border: none;
  height: 70px;
  background-color: transparent;
  color: #fff;
  font-size: 1.3rem;
  position: relative !important;
}

.btn-transparent {
  background-color: transparent !important;
  border: 2px solid #000;
  color: #000;
}

.btn-transparent--blue {
  background-color: transparent !important;
  color: var(--brand-blue);
  border: 3px solid var(--brand-blue);
}

.btn-transparent--purple {
  background-color: transparent !important;
  color: var(--base-purple);
  border: 3px solid var(--base-purple);
  border-radius: 0;
}

@media screen and (max-width: 500px) {
  .btn-iconwithtext {
    color: #fff !important;
  }
}
