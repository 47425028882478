.herosection {
  min-height: initial;
  padding-bottom: 0 !important;
}

.herosection__bgimage {
  height: 80vh !important;
  background-image: url(https://res.cloudinary.com/learnhat/image/upload/v1632889331/website_images/Login_Home_BG_zk0tnz.jpg);
  background-attachment: initial;
  background-size: cover;
}

.imagecontainer {
  width: 50%;
  height: 90%;
  display: flex;
  align-items: flex-end;
}

.herosection__image {
  margin-bottom: -4px;
}

.twu__why {
  padding: 2rem var(--body-side-paddings);
  background-color: #fff;
}

.twu__whycards {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}

.twu__steps {
  padding: 2.5rem var(--body-side-paddings);
  margin-bottom: 1rem;
}

.twu__steps-cards {
  padding: 5rem 0 0 0;
  display: flex;
  justify-content: space-between;
}

.twu__faq {
  width: 100%;
  height: initial;
  background-color: #fff;
  padding: 2rem var(--body-side-paddings);
}

.twu__faq-container {
  width: 100%;
  margin-top: 2rem;
}

.twu__faq-card {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem 1.5rem;
  box-shadow: var(--box-shadow2);
}

.twu__faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
  height: 50px;
}

.twu__faq-question-container {
  height: 100%;
  display: flex;
}

.twu__faq-question-text {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
}

.twu__faq-answer {
  padding: 0 0 0.5rem 0;
  display: none;
}

.twu__faq-answer.answer-active {
  display: block;
}

.twu__faq-answertext {
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
}

.twu__terms {
  width: 70%;
  margin: 2rem auto;
  text-align: center;
}

.twu__terms > p {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

@media screen and (max-width: 500px) {
  .imagecontainer {
    width: 100%;
    height: 30% !important;
    overflow: hidden;
    display: initial;
  }
  .twu__hero-cta{
    display: flex;
    justify-content: center;
  }
  .herosection__image {
    height: 300px;
    width: 100%;
    object-fit: cover;
    transform: scale(0.6);
    margin-right: 2rem;
  }
  .twu__why {
    padding: 1rem;
    background-color: #fff;
  }
  .twu__whycards {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }
  .twu__steps {
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .twu__steps-cards {
    flex-direction: column;
    align-items: center;
  }
  .twu__faq {
    padding: 1rem;
    padding-top: 2rem;
  }
}
