:root {
  --main-font: "Montserrat", sans-serif;
  --reey-font: "reeyregular";
  --base-purple: #2a0e59;
  --primary: #ffd45f;
  --gray: #757783;
  --link-gray: #aeb2c2;
  --light-gray: #626368;
  --theme-black: #1c1e27;
  --theme-black-rgb: 28, 30, 39;
  --brand-yellow: #ffba1b;
  --brand-blue: #0b44b3;
  --red: #fc0200;
  --blue: #28abfd;
  --green: rgb(106, 219, 106);
  --body-side-paddings: 11rem;
  --box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.06);
  --box-shadow2: 2px 2px 20px 0 rgba(0, 0, 0, 0.06);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--main-font);
}

body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: #fff;
}

.mr-05 {
  margin-right: 0.5rem;
}

.mt-05 {
  margin-top: 0.5rem;
}

.mt-02 {
  margin-top: 0.2rem;
}

.mt-07 {
  margin-top: 0.7rem;
}

.mt-1 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-105 {
  margin-top: 1.5rem;
}

.m-03 {
  margin: 0 0.5rem;
}

.mr-1 {
  margin-right: 2rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-0 {
  margin-left: 0;
}

.ml-05 {
  margin-left: 0.5rem;
}

.m-05 {
  margin: 0 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.relative {
  position: relative;
}

.color--brandblue {
  color: var(--brand-blue);
}

.color--purple {
  color: var(--base-purple);
}

.color--brandyellow {
  color: var(--brand-yellow);
}

.color--blue {
  color: var(--blue);
}

.color--red {
  color: var(--red);
}

.error-message {
  font-size: 0.9rem;
  color: var(--red);
}

.center {
  text-align: center;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: rgb(var(--theme-black-rgb), 0.7);
}

.overlay--dark {
  background-color: rgb(var(--theme-black-rgb), 0.95);
}

.left-image {
  position: absolute;
  right: 1rem;
  z-index: 2;
}

.top-right-image {
  position: absolute;
  left: 1rem;
  bottom: 13.5%;
  z-index: 9;
  border-radius: 0.5rem;
}

.floatingdiv {
  height: 200px;
  width: 50px;
  background-color: var(--base-purple);
  border-radius: 0.5rem;
  position: absolute;
  top: 10%;
  left: 14%;
  z-index: 1;
}

.navbar--active {
  position: fixed;
  top: 0px;
  height: 90px;
  z-index: 9999;
  background-color: #fff;
  transition: all ease-in 0.2s;
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.05);
}

.navbar--active a {
  color: #000 !important;
}

.navbar--active .menu-icon {
  color: #000 !important;
}

.navbar--active .nav-icon-btn {
  color: #000 !important;
}

.navbar--active .nav-submenu-link:hover {
  color: #fff;
}

.phone-icon {
  color: #000;
}

.border-round {
  border-radius: 0.5rem;
}

.pill-badge {
  padding: 0.6rem 1rem;
  border-radius: 1.5rem;
  margin: 0 0.5rem 0 0;
  font-size: 0.9rem;
  color: #fff;
}

.round {
  border-radius: 50%;
  object-fit: cover;
}

.floating_shape1 {
  position: absolute;
  bottom: 0%;
  right: 65%;
  z-index: 999;
  opacity: 0.25;
}

.floating_shape2 {
  position: absolute;
  left: 80%;
  bottom: 0%;
  z-index: 999;
}

.video-player {
  width: 100%;
  height: 100%;
}

.img-testimonial {
  margin-top: 1rem;
}

.carousel img {
  width: initial !important;
}

.carousel-container {
  height: 50vh;
  width: 80%;
  display: flex;
  justify-content: center;
  position: relative;
}

.custom-carousel {
  width: 70% !important;
  position: absolute;
  top: 0%;
  overflow: visible !important;
}

.carousel {
  overflow: visible !important;
}

.carousel .control-dots {
  position: absolute;
  bottom: -12%;
}

.carousel .control-dots .dot {
  width: 15px;
  height: 15px;
  background-color: #28abfd;
  opacity: 1;
  box-shadow: none;
  margin: 0 0.3rem;
}

.carousel .control-dots .dot.selected {
  background-color: var(--base-purple);
}

.input-black {
  width: 250px;
  height: 40px;
  color: #b7b7b7;
  font-weight: 700;
  font-size: 0.9rem;
  padding: 1rem;
  background-color: #000 !important;
}

.links-cta {
  height: 40px;
  width: 100px;
  font-weight: 500;
  font-size: 0.9rem;
  padding: 0.2rem;
  margin-left: 0.3rem;
  border-radius: 0.45rem;
}

.menu-icon {
  display: none;
}

.test-form {
  text-align: center;
  padding: 2rem;
}

.bgcolor--blue {
  background-color: var(--brand-blue);
}

.bgcolor--lightblue {
  background-color: var(--blue);
}

.bgcolor--red {
  background-color: var(--red);
}

.bgcolor--green {
  background-color: var(--green);
  color: #000;
}

.color--blue {
  color: var(--brand-blue);
}

.bgcolor--yellow {
  background-color: var(--brand-yellow);
}

.color--yellow {
  color: var(--brand-yellow);
}

.color--black {
  color: #000;
}

.bgcolor--purple {
  background-color: var(--base-purple);
}

.center {
  text-align: center;
}

.flex {
  display: flex;
}

.al-center {
  align-items: center;
}

.jc-center {
  justify-content: center;
}

.column {
  flex-direction: column;
}

.bold {
  font-weight: 600;
}

.tc {
  display: inline-block;
  margin: 1rem 0;
  font-size: 0.68rem;
}

.tc-input {
  display: flex;
  align-items: center;
}

.validation-message {
  color: var(--red);
  font-size: 0.8rem;
  display: inline-block;
  margin: 0.5rem;
}

.heading,
.heading2 {
  font-size: 2.5rem;
  text-align: center;
}

.heading2 {
  font-size: 1.6rem;
}

.subheading {
  font-size: 0.9rem;
  text-align: center !important;
  margin-top: 0.5rem;
  line-height: 1.2rem;
  font-weight: 400;
}

@media screen and (max-width: 500px) {
  .menu-icon {
    display: block !important;
    color: #000;
  }
  .small-input {
    height: 45px;
    font-size: 0.9rem;
    width: 100px;
  }

  .small-btn {
    height: 45px;
    width: 100px;
    font-size: 0.6rem;
  }

  .left-image {
    right: 1rem;
    bottom: 0;
    z-index: 2;
    height: 320px;
  }

  .top-right-image {
    left: 0;
    bottom: 0;
    height: 150px;
  }

  .floatingdiv {
    height: 130px;
    top: 13%;
    left: 10%;
  }

  .carousel-container {
    height: 40vh;
    width: 100%;
    padding: 0 !important;
  }

  .custom-carousel {
    width: 100% !important;
    padding: 0 !important;
  }

  .input-black {
    width: 200px;
    font-size: 0.9rem;
    padding: 1rem;
  }

  .links-cta {
    height: 40px;
    width: 100px;
    font-size: 0.75rem;
  }
  .pill-badge {
    padding: 0.6rem;
    font-size: 0.75rem;
  }

  .heading {
    font-size: 1.5rem;
    text-align: center;
  }
}
