.card-icon-h {
  padding: 2.5rem 1.5rem;
  display: flex;
  box-shadow: var(--box-shadow2);
}

.card-icon-h__headingtext {
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin-left: 1rem;
  font-weight: 600;
  color: var(--base-purple);
}

.card-icon-h-sh {
  display: flex;
  border-radius: 0.5rem;
  padding: 1.5rem 2rem;
  box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.06);
}

.card-icon-h-sh__text {
  margin-left: 1rem;
}

.card-icon-h-sh__heading {
  font-size: 1.05rem;
  line-height: 1.8rem;
}

.highlight {
  padding: 0.2rem 0.4rem;
  border-top-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  color: #fff !important;
  margin: 0 0.2rem;
  font-weight: 600;
  font-size: 1rem;
}

.card-icon-h-sh:nth-child(1)
  > .card-icon-h-sh__text
  > .card-icon-h-sh__subheading
  > .highlight {
  background-color: var(--red);
}

.highlight--purple {
  background-color: var(--base-purple);
}

.card-icon-h-sh__subheading {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.8rem;
  margin-top: 0.4rem;
}

.card-teacherinfo {
  padding: 2rem 1rem;
  height: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--gray);
  border-radius: 0.5rem;
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.06);
}

.card-teacherinfo__name {
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 1rem;
  color: #000;
}

.card-teacherinfo__designation {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: var(--base-purple);
}

.card-teacherinfo__experience {
  margin-top: 1rem;
  font-size: 0.9rem;
}

.card-teacherinfo__expert-at {
  font-size: 1rem;
}

.card-teacherinfo__education {
  font-size: 1rem;
  margin-top: 1rem;
}

.card-teacherinfo__courses {
  width: 100%;
  border-radius: 0.5rem;
  padding: 1.3rem 2rem;
  background-color: #f1f2f6;
  margin-top: 1.5rem;
}

.card-teacherinfo__courses > p {
  font-size: 0.9rem;
  text-align: center;
}

.metric-card {
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.06);
}

.metric-card__number {
  font-weight: 700;
  font-size: 1.6rem;
  color: var(--base-purple);
}

.metric-card__message {
  color: #000;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 1rem;
  line-height: 1.2rem;
  width: 70%;
}

.card-testimonial {
  width: 100%;
  height: 51vh;
  background-color: var(--base-purple);
  padding: 2rem;
  color: #fff;
  border-radius: 0.5rem;
}

.card-testimonial__data {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.card-testimonial__img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #fff;
}

.card-testimonial__text {
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.card-testimonial__desg {
  margin-top: 0.5rem;
}

.card-testimonial__img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-testimonial__video {
  width: 100%;
  height: 90% !important;
}

.card-testimonial__detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-testimonial__name {
  font-weight: 600;
  font-size: 1.1rem;
  margin-top: 1rem;
}

.spkeng-card {
  width: 30%;
  justify-content: space-between;
  align-items: center;
}

.spkeng-card__text {
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 0rem;
  width: 100%;
  text-align: center;
  height: 60px;
  color: var(--base-purple) !important;
}

.progress-card {
  flex-direction: row;
  background-color: #fff;
  padding: 1rem;
}

.progress-card__text {
  margin-left: 1rem;
  min-height: 50px;
  display: flex;
  flex-direction: column;
}

.progress-card__heading {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0rem;
  width: 100%;
  width: 50%;
  color: var(--base-purple);
}

.progress-card__subheading {
  color: #000;
  font-size: 0.8rem;
  margin-top: 0.3rem;
  width: 60%;
  line-height: 1.2rem;
}

.featicon-card {
  padding: 1rem;
  border-radius: 0.5rem;
}

.featicon-card > p {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  height: 40px;
}

.hglticon-card {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  padding: 1rem 0;
  justify-content: space-between;
  height: initial;
}

.hglticon-card > p {
  font-size: 1rem;
  font-weight: 400;
}

.hglticon-card > img {
  height: 80px;
}

.pricingcard {
  padding: 1rem;
  box-shadow: var(--box-shadow2);
  width: 30%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricingcard > h2 {
  font-weight: 700;
  font-size: 1.6rem;
  color: var(--base-purple);
}

.pricingcard > h3 {
  font-size: 1.4rem;
  color: var(--brand-yellow);
  margin-top: 0.5rem;
  font-weight: 800;
}

.pricingcard > p {
  font-size: 1rem;
  color: var(--brand-yellow);
  margin-top: 0.5rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricingcard > p > span {
  font-size: 0.9rem;
  color: #000;
  font-weight: 400;
  margin-right: 0.5rem;
}

.pricingcard > div {
  margin-top: 1rem;
}

.pricingcard > div > p {
  font-size: 0.8rem;
  font-weight: 400;
}

.circlecard {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: var(--base-purple);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 3rem 1rem;
}

.circlecard > p {
  font-weight: 500;
  text-align: center;
  width: 90%;
  font-size: 0.75rem;
}

.circlecard-numimg {
  margin-top: -7rem;
  transform: scale(0.55);
}

@media screen and (max-width: 500px) {
  .card-icon-h-sh__heading {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .card-icon-h-sh__subheading {
    font-size: 0.9rem;
  }
  .highlight {
    padding: 0.2rem 0.4rem;
    font-size: 0.8rem;
  }

  .card-testimonial {
    width: 100%;
    height: 37vh;
    padding: 1rem;
  }
  .card-testimonial__data {
    flex-direction: column;
    height: 100%;
    justify-content: initial;
  }
  .card-testimonial__video {
    width: 100%;
    height: 100%;
  }

  .card-testimonial__detail {
    width: 100%;
  }

  .card-testimonial__name {
    margin-top: 1rem !important;
  }

  .spkeng-card {
    width: 100%;
  }
  .progress-card {
    margin-bottom: 1rem;
  }

  .progress-card__heading {
    width: 70%;
  }

  .progress-card__subheading {
    width: 70%;
  }
  .featicon-card {
    padding: 0.5rem;
  }
  .featicon-card > p {
    font-size: 0.9rem;
  }
  .hglticon-card {
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0;
  }
  .hglticon-card > p {
    font-size: 0.9rem;
  }
  .pricingcard {
    padding: 1rem;
    box-shadow: var(--box-shadow2);
    width: 100%;
    margin-bottom: 1rem;
  }
  .circlecard {
    margin-bottom: 3rem;
  }
  .circlecard:nth-child(3) {
    margin-bottom: 0rem !important;
  }
  .card-testimonial__img {
    width: 100px;
    height: 100px;
  }
  .card-testimonial__text {
    font-size: 0.7rem;
    margin-top: 0.5rem;
  }
}
