.navbar {
  width: 100vw;
  height: 90px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 2rem;
  background-color: #fff !important;
}

.navbar--active a:hover{
  font-weight: 600;
  color: var(--base-purple) !important;
}

.navbar__links {
  width: 80vw !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar__linkitems {
  text-decoration: none;
  font-weight: 700;
  color: #000;
  font-size: 0.9rem;
}

.navbar__linkitems:hover{
  color: var(--base-purple)
}

.navbar__submenu {
  min-width: 230px;
  height: initial !important;
  background-color: #fff;
  display: none;
  position: absolute;
  bottom: -100px;
  left: -10%;
  z-index: 99;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.navbar__submenu--lvl2{
  position: absolute;
  top: 0;
  left: 100%;
  height: 100px !important;
}

.navbar__submenu--lvl2--3item{
  height: 150px !important;
}

.navbar__submenu--three-item{
  bottom: -150px
}

.navbar__submenu--one-item{
  bottom: -50px;
  left: -180px;
}

.navbar__submenu-item{
  display: inline-block;
  display: flex;
  width: 100% !important;
  padding: 1rem;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.navbar__submenu-item:hover{
  background-color: var(--base-purple);
  color: #fff;
}

.navbar__submenu-item:hover{
  background-color: var(--base-purple);
  color: #fff !important;
}

.active {
  display: block !important;
}


@media screen and (max-width: 500px) {
  .navbar__links{
    display: none;
  }
  .navbar{
    padding: 0;
    height: 100px;
    padding: 1rem;
  }
}