.sections {
  width: 100%;
  height: 60vh;
  padding: 2rem var(--body-side-paddings);
  display: flex;
}

.sections__type {
  width: 50%;
  height: 100%;
  padding: 1rem;
  position: relative;
}

.sections__textfloater {
  width: 58%;
  background-color: var(--base-purple);
  border-radius: 0.5rem;
  height: 40%;
  position: absolute;
  bottom: 50px;
  right: 1rem;
  z-index: 9;
  padding: 2rem;
  color: #fff;
}

.section__heading {
  font-size: 1.4rem;
}

.section__subheading {
  font-size: 1rem;
  color: #d3d8ff;
  line-height: 1.5rem;
  margin-top: 1rem;
}

@media screen and (max-width: 500px) {
  .sections {
    padding: 1rem;
    flex-direction: column;
    height: initial;
  }

  .sections__type {
    width: 100%;
    padding: 0;
    height: 50vh;
  }

  .sections__type > img {
    width: 100%;
  }

  .sections__textfloater {
    width: 90%;
    height: initial;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
    text-align: center;
  }
}
