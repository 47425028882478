.herosection {
  height: initial;
}

.herosection__bgimage {
  height: 100vh;
  background-image: url(https://res.cloudinary.com/learnhat/image/upload/v1632889331/website_images/Login_Home_BG_zk0tnz.jpg);
  background-attachment: initial;
  background-size: cover;
  background-position: 0% 100%;
}

.herosection__image {
  height: initial;
}

.spokeneng__learn-better {
  padding: 1rem var(--body-side-paddings);
  display: flex;
  justify-content: space-between;
}

.spokeneng__progress {
  height: 85vh;
  background: linear-gradient(360deg, #ffba1b 0%, rgba(255, 255, 255, 1) 100%);
  padding: 2rem var(--body-side-paddings);
}

.spokeneng__progress-content {
  display: flex;
  width: 100%;
  height: 70%;
  margin-top: 4rem;
}

.spokeneng__prgcards,
.spokeneng__prgboard {
  width: 50%;
  height: 100% !important;
}

.spokeneng__prgcards {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.spokeneng__prgboard {
  display: flex;
  justify-content: center;
}

.spokeneng__prgcards {
  padding: 0 3rem;
  position: relative;
}

.floating-prgline {
  position: absolute;
  right: 3rem;
  transform: scale(0.9);
  top: 0.7rem;
}

.spokeneng__prgboard {
  position: relative;
}

.spokeneng__prgboard-content {
  width: 65%;
  height: 100% !important;
  padding: 1rem;
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-radius: 0.5rem;
}

.spokeneng__prgboard-content > h2 {
  font-size: 1.1rem;
  /* text-align: center; */
}

.spokeneng__keyfeatures {
  padding: 0;
  border-radius: 0.5rem;
}

.spokeneng__keyfeatures > div {
  display: flex;
  align-items: center;
  margin: 0.9rem 0 0 0;
  width: 100%;
  height: 30px;
}

.spokeneng__keyfeatures > div > img {
  height: 12px;
}

.spokeneng__keyfeatures > div > p {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  font-size: 0.8rem;
  font-weight: 400;
}

.spokeneng__keyfeat {
  height: 90vh;
  padding: 3rem var(--body-side-paddings);
  display: flex;
  justify-content: space-between;
}

.spokeneng__keyfeat > div {
  width: 48%;
  height: 95%;
  background-color: var(--base-purple);
  border-radius: 0.5rem;
}

.spokeneng__keyfeat > div {
  padding: 2rem;
  color: #fff;
  text-align: center;
}

.spokeneng__keyfeat > div > p {
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.spokeneng__keyfeat > div > h2 {
  font-size: 1.3rem;
  font-weight: 600;
}

.spokeneng__keyfeat > div:nth-child(1) {
  border-top-left-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

.spokeneng__keyfeat > div:nth-child(2) {
  border-top-right-radius: 5rem;
  border-bottom-left-radius: 5rem;
}

.spokeneng__keyfeat-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2rem;
  grid-gap: 2rem;
}

.spokeneng__hglt-cards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 2rem;
  grid-gap: 1rem;
}

.spokeneng__pricing {
  padding: 2rem var(--body-side-paddings);
}

.spokeneng__pricingfeats {
  width: 50%;
  margin: 0 auto;
  margin-top: 2rem;
}

.spokeneng__pricingfeats > div {
  justify-content: center;
  align-items: center;
  height: 20px;
}

.spokeneng__pricingfeats > div > p {
  font-weight: 450;
  text-align: center;
  font-size: 0.8rem;
  letter-spacing: 1px;
}

.spokeneng__pricingcards {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

@media screen and (max-width: 500px) {
  .spokeneng__learn-better {
    padding: 1rem;
    flex-direction: column;
  }

  .spokeneng__progress {
    padding: 1rem;
    height: initial;
  }

  .spokeneng__progress-content {
    flex-direction: column;
  }

  .spokeneng__prgcards,
  .spokeneng__prgboard {
    width: 100%;
    height: initial;
  }

  .spokeneng__prgcards {
    padding: 0;
  }

  .spokeneng__keyfeat {
    padding: 1rem;
    height: initial;
    flex-direction: column;
  }

  .spokeneng__keyfeat > div {
    width: 100%;
    border-radius: 2rem;
    padding: 1rem;
  }

  .floating-prgline {
    right: 1rem;
    top: 0;
    transform: scale(0.8);
  }
  .spokeneng__prgboard-content {
    width: 100%;
  }
  .spokeneng__keyfeat > div:nth-child(1) {
    border-top-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
  }

  .spokeneng__keyfeat > div:nth-child(2) {
    border-top-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
    margin-top: 1rem;
  }
  .spokeneng__keyfeat-cards {
    padding: 1rem;
    grid-gap: 1rem;
  }
  .spokeneng__hglt-cards {
    padding: 0rem;
  }
  .spokeneng__pricing {
    padding: 1rem;
  }
  .spokeneng__pricingcards {
    flex-direction: column;
  }
  .spokeneng__pricingfeats {
    width: 100%;
  }
}
