.navbar{
    width: 100%;
    height: 100vh;
    background-color: var(--theme-black);
    padding: 1rem;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: -100%;
    transition: all ease-in 0.3s;
    overflow-y: scroll !important;
}

.active{
    left: 0;
}

.navbar__brand{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 2rem;
}

.navbar__links{
    display: flex;
    flex-direction: column;
}

.navbar__dropdown-menuitem{
    padding: 1rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar__linkitems, .navbar__submenu-item{
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 1rem 0;
    font-size: 0.9rem;
    font-weight: 700;
}

.navbar__dropdown-menuitem{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.navbar__submenu{
    display: none;
}

.navbar__submenu-item{
    margin-left: 0.5rem;
}

.navbar__submenu-item--lvl2{
    margin-left: 1rem;
}


.submenuactive{
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    height: initial;
}

.navbar__lang{
    margin: 0.5rem 0;
    padding: 0.3rem;
}

.navbar__contact-info{
    margin-top:1rem;
    display: flex;
    align-items: center;
}

.navbar__contact-info a{
    font-size: 0.9rem;
}

.contact-icon{
    padding: 0.4rem;
    background-color: var(--base-purple);
    font-size: 1.7rem;
    color: #fff;
    margin-right: 0.5rem;
    border-radius: 50%;
}

.navbar__social{
    margin-top: 1rem;
    width: 100%;
}

.navbar__social a{
    margin: 0 0.3rem;
    color: var(--blue);
}