.coursedetail {
  width: 100%;
}

.coursedetail-input {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 1rem var(--body-side-paddings);
  grid-column-gap: 2rem;
}

.coursedetail__features {
  padding: 3rem var(--body-side-paddings);
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1631565941/website_images/coursedetails-page-header-bg.jpg");
  background-size: cover;
  background-position: center;
  height: initial;
  color: #fff;
}

.coursedetail__feature-heading {
  font-size: 2.5rem;
  font-weight: 600;
}

.coursedetail__badges {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 1rem;
}

.coursedetail__keyfeatures {
  margin-top: 2rem;
}

.coursedetail__keyfeatures > h2 {
  font-size: 1.2rem;
}

.coursedetail__keyfeatures > div {
  display: flex;
  align-items: center;
  margin: 0.2rem 0 0 0;
  width: 100%;
  height: 30px;
}

.coursedetail__keyfeatures > div > img {
  height: 15px;
}

.coursedetail__keyfeatures > div > p {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  font-size: 0.9rem;
  font-weight: 300;
}

.coursedetails__batches {
  padding: 2rem var(--body-side-paddings);
}

.coursedetail__batch {
  padding: 1rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  display: flex;
  margin-bottom: 1rem;
  border-radius: 1rem;
}

.coursedetail__batchdetail {
  width: calc(100% / 5);
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}

.coursedetail__batchdetail > h1 {
  font-size: 1.3rem;
  font-weight: 500;
  padding-bottom: 1rem;
  border-bottom: 1px solid #000;
  margin-bottom: 1.5rem;
  text-align: center;
}

.coursedetail__batchdetail:nth-child(3) {
  width: 30%;
}

.coursedetail__batchdetail > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detailscard--active {
  background-color: var(--base-purple) !important;
  color: #fff;
  border-radius: 1rem;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
}

.detailscard--active button {
  background-color: #fff;
  color: var(--base-purple);
  font-weight: 800 !important;
}

.detailscard--active h1 {
  border-bottom: 2px solid #fff;
}

.coursedetail__plans {
  padding: 2rem var(--body-side-paddings);
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
}

.coursedetail__plancard {
  border-radius: 0.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-align: center;
  height: 200px;
}

.coursedetail__plancard > h1 {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.coursedetail__plancard > h2 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.coursedetail__plancard p {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.planscard--active {
  background-color: var(--base-purple) !important;
  color: #fff;
  border-radius: 1rem;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
}

.planscard--active button {
  background-color: #fff;
  color: var(--base-purple);
  font-weight: 800 !important;
}

.coursedetail__cta {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

@media (max-width: 500px) {
  .coursedetail-input {
    grid-template-columns: 1fr;
    padding: 1rem;
    grid-row-gap: 1rem;
  }
  .coursedetail__features {
    padding: 1rem;
  }
  .coursedetail__feature-heading {
    font-size: 1rem;
    text-align: center;
  }
  .coursedetail__badges {
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 150px;
  }
  .coursedetail__keyfeatures > h2 {
    font-size: 1.2rem;
    text-align: center;
  }
  .coursedetail__keyfeatures > div {
    display: flex;
    align-items: center;
    margin: 0.5rem 0 0 0;
    width: 100%;
    height: 50px;
  }
  .coursedetails__batches {
    padding: 1rem;
  }
  .coursedetail__batch {
    display: flex;
    flex-direction: column;
  }
  .coursedetail__batchdetail {
    width: 100%;
    margin-bottom: 1rem;
  }
  .coursedetail__batchdetail:nth-child(3) {
    width: 100%;
  }
  .coursedetail__plans {
    padding: 1rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr;
  }
}
