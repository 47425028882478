.testimonial {
  width: 100%;
  height: initial;
  background-color: #fff;
  position: relative;
  padding: 2rem var(--body-side-paddings);
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.testimonial__text {
  width: 25%;
}

.testimonial__text > p {
  font-size: 1rem;
}

.testimonial__heading {
  text-align: center;
  font-size: 1.7rem;
  color: #000;
  margin-top: 0.5rem;
}

@media screen and (max-width: 500px) {
  .testimonial {
    flex-direction: column;
    padding: 1rem;
  }
  .testimonial__text {
    width: 100%;
    margin-bottom: 1rem;
  }

  .testimonial__heading {
    font-size: 1.5rem;
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
