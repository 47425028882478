.footer {
  padding: 1rem var(--body-side-paddings);
  background-color: var(--base-purple);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  color: var(--link-gray);
}

.footer__links {
  font-size: 0.8rem;
  color: var(--link-gray);
}

@media screen and (max-width: 500px) {
  .footer {
    padding: 1rem;
    height: 80px;
    flex-direction: column;
  }

  .footer__links {
    font-size: 0.6rem;
  }
}
