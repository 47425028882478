.links__contact {
  background-color: var(--base-purple);
  width: 100%;
  height: 30vh;
  padding: 4rem var(--body-side-paddings);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #51248a;
}

.links__social {
  width: 15%;
}

.links__social-links {
  display: flex;
  justify-content: space-between;
}

.links__socialicon {
  width: 40px;
  height: 40px;
  background-color: #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.links__app-link {
  width: 45%;
}

.links__app-link-heading {
  color: #fff;
  font-size: 1.1rem;
}

.links__app-link-bottom {
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
}

.links__app-link-input {
  display: flex;
  align-items: center;
  margin-left: 0.3rem;
}

.links__contact-details {
  width: 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.links__contact-heading {
  font-size: 1.1rem;
  color: #fff;
}

.links__contact-details > p {
  color: #b3b3b3;
  font-size: 0.95rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.links__display {
  width: 100%;
  height: 90vh;
  background-color: var(--base-purple);
  display: flex;
  padding: 4rem var(--body-side-paddings);
}

.links__section1 {
  width: 45%;
  display: flex;
}

.links__section2 {
  width: 55%;
  display: flex;
}

.links__quicklinks {
  width: 40%;
}

.links__scholarships {
  width: 60%;
}

.links__library {
  width: 30%;
}

.links__more1 {
  width: 35%;
}

.links__more2 {
  width: 35%;
}

.links__column {
  display: flex;
  flex-direction: column;
}

.links__title {
  color: #fff;
  font-size: 1.1rem;
}

.links__subtitle {
  color: var(--brand-yellow);
  font-size: 1.1rem;
  margin-top: 1rem;
}

.links__link {
  font-size: 0.95rem;
  color: var(--link-gray);
  margin-top: 1rem;
}

.links__link--yellow {
  color: var(--brand-yellow);
  margin-top: 0;
  margin-bottom: 1rem;
}

@media screen and (max-width: 500px) {
  .links__contact {
    padding: 1rem;
    flex-direction: column;
    height: 70vh;
    align-items: initial;
  }

  .links__social {
    width: 50%;
  }

  .links__app-link {
    width: 100%;
    margin-top: 1rem;
  }

  .links__app-link-heading {
    font-size: 1rem;
  }

  .links__app-link-bottom {
    flex-wrap: wrap;
  }

  .links__app-link-input {
    width: 100%;
    margin-left: 0;
  }

  .links__contact-details {
    width: 50%;
    height: 120px;
  }

  .links__display {
    height: initial;
    padding: 1rem;
    flex-direction: column;
  }

  .links__link {
    font-size: 0.8rem;
  }

  .links__section1 {
    width: 100%;
    justify-content: space-between;
  }

  .links__quicklinks {
    width: 40%;
  }

  .links__scholarships {
    width: 60%;
  }

  .links__section2 {
    width: 100%;
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  .links__library {
    width: 50%;
  }

  .links__more1 {
    width: 50%;
  }

  .links__more2 {
    width: 50%;
    margin-top: 2rem;
  }
}
