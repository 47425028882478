.input{
    width: 100%;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #F1F2F6;
    border: none;
    font-size: 1rem;
    color: rgb(0, 0, 0);
}

.input::placeholder{
    font-size: 1rem;
    color: rgb(0, 0, 0);
}

.input-select{
    font-size: 1rem;
    color: rgb(0, 0, 0);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.input-countrycode{
  background-color: #F1F2F6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px !important;
  height: 20px !important;
  font-weight: 400;
  font-size: 1rem;
  padding-left: 1rem;
  position: absolute;
  left: 0.2rem;
  top: 50% !important;
  transform: translateY(-50%) !important;
  color: #000 !important; 
}

.mobilenumber{
  display: flex;
  align-items: center !important;
  position: relative;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.mobilenumber input{
  width: 100%;
  padding-left: calc(40px + 1rem);
  font-size: 1rem;
  
}

.inputmobilenumber{

}
