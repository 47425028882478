.contact-bar{
    height: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem var(--body-side-paddings);
    background-color: var(--base-purple);
    color: #fff;
}

.contact-bar__info{
    display: flex;
    align-items: center;
    font-size: 0.87rem;
    font-weight: 400;
}

.contact-bar__social{
    width: 13.5%;
    border-left: 2px solid #33353D;
    padding-left: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
}

@media screen and (max-width: 500px) {
    .contact-bar{
        display: none;
    }
}