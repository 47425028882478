.best-teachers {
  width: 100%;
  padding: 0 var(--body-side-paddings);
  padding-bottom: 3rem;
}

.best-teachers__title {
  color: var(--base-purple);
  text-align: center;
  font-size: 1.1rem;
}

.best-teachers__heading {
  text-align: center;
  font-size: 1.6rem;
  margin-top: 1rem;
}

.best-teachers__display {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  margin-top: 4rem;
}

.best-teachers__cta {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

@media screen and (max-width: 500px) {
  .best-teachers {
    padding: 1rem;
  }

  .best-teachers__display {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    margin-top: 4rem;
  }
  .best-teachers__cta {
    width: 100%;
    margin-top: 2rem;
  }
}
