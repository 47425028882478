.about {
  padding: 1rem var(--body-side-paddings);
  background-color: var(--base-purple);
}

.about__section {
  padding: 0 0 0.8rem 0;
  border-bottom: 2px solid #b7b7b7;
}

.about__text {
  font-size: 0.8rem;
  line-height: 1.5rem;
  font-weight: 400;
  color: var(--link-gray);
}

@media screen and (max-width: 500px) {
  .about {
    padding: 1rem;
  }
  .about__text {
    font-size: 0.7rem;
  }
}
