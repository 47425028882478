.paymentdetails {
  width: 100%;
  height: 100vh;
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1631711418/website_images/log-bg_ep4duv.jpg");
  background-size: cover;
  background-position: center;
  padding: 2rem var(--body-side-paddings);
}

.paymentdetails__content {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-between;
}

.paymentdetails__left {
  width: 45%;
}

.paymentdetails__subjectplaninfo {
  color: #fff;
  font-size: 1.5rem;
}

.subject-name {
  font-size: 1.8rem;
}

.plan-type {
  font-size: 1rem;
}

.plan-price {
  padding: 1rem 1.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  margin-top: 2rem;
  width: 180px;
}

.paymentdetails__userdetails {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.paymentdetails__userdetails div {
  width: 48%;
}

.paymentdetails__userdetails div span {
  font-weight: 700;
}


.paymentdetails__coupon {
  display: flex;
  margin-top: 2rem;
}

.paymentdetails__coupon input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.paymentdetails__right {
  width: 45%;
  height: 100%;
  background-color: #fff;
  padding: 2rem;
}

.paymentdetails__emi {
  margin-top: 1rem;
}

.paymentdetails__emi p {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.paymentdetails__emi span {
  font-weight: 800;
  font-size: 1.2rem;
  color: var(--brand-blue);
}

.paymentdetails__emi-plans{
  margin-top: 1rem;
}

.paymentdetails__emi-plans p{
  font-size: 0.9rem;
}

.paymentdetails__gateway {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.paymentdetails__gateway p {
  margin-left: 0.6rem;
}

@media (max-width: 500px) {
  .paymentdetails {
    padding: 1rem;
    height: initial;
  }
  .paymentdetails__content {
    height: initial;
    flex-direction: column;
  }
  .paymentdetails__left {
    width: 100%;
  }
  .paymentdetails__userdetails {
    flex-direction: column;
    margin-top: 0;
  }

  .paymentdetails__userdetails input {
    width: 100%;
    margin-top: 2rem;
  }

  .paymentdetails__right {
    width: 100%;
    margin-top: 2rem;
  }
}
