.login {
  width: 100%;
  height: 100vh;
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1631711418/website_images/log-bg_ep4duv.jpg");
  background-size: cover;
  background-position: center;
  padding: 0 var(--body-side-paddings);
}

.login__content {
  width: 100%;
  height: 100%;
  display: flex;
  background-position: center;
  background-size: cover;
}

.login__content-left {
  width: 50%;
  height: 100%;
  padding-top: 3rem !important;
  color: #fff;
}

.login__header {
  font-size: 2rem;
  font-weight: 400;
}

.login__header-large {
  font-size: 3rem;
}

.login__header-highlight {
  padding: 0.2rem 0.5rem;
  background-color: var(--red);
}

.login__content-right {
  width: 50%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__input {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: #fff;
}

.login__input > h1 {
  color: #000;
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

.login__input > button {
  margin-top: 2rem;
}

.login__input > p {
  cursor: pointer;
}

.login__input > input {
  font-size: 1.5rem;
  text-align: center;
}

.login__get-applink {
  height: 30%;
  padding: 0 var(--body-side-paddings);
  margin-top: -4rem;
}

@media (max-width: 500px) {
  .login {
    padding: 1rem;
  }
  .login__content {
    width: 100%;
    height: initial;
    flex-direction: column;
  }
  .login__content-left {
    width: 100%;
    height: initial;
    padding-top: 0;
  }
  .login__header {
    font-size: 1.2rem;
    text-align: center;
    line-height: 2.5rem;
  }
  .login__header-large {
    font-size: 2rem;
  }
  .login__content-right {
    width: 100%;
  }
  .login__input {
    width: 90%;
    margin-top: 2rem;
  }
  .login__get-applink {
    height: initial;
    padding: 1rem;
    margin-top: -4rem;
  }
}
