.terms{
    width: 60%;
    margin: 0 auto;
}

.terms p{
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.terms__heading{
    font-size: 2rem;
    margin-bottom: 1.5rem 0;
}

.terms__subheading{
    margin: 1.5rem 0;
}

ol{
    margin-left: 2rem;
}

li{
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
}

@media screen and (max-width: 500px) {
    .terms{
        width: 100%;
        padding: 1rem;
    }
}