.courses {
  width: 100%;
  overflow: hidden;
}

.courses__features {
  padding: 3rem var(--body-side-paddings);
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1631565941/website_images/coursedetails-page-header-bg.jpg");
  background-size: cover;
  background-position: center;
  height: 70vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.courses__feature-heading {
  font-size: 2.5rem;
  font-weight: 600;
}

.courses__badges {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.courses__keyfeatures {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  width: 60%;
  color: #000;
}

.courses__keyfeatures > h2 {
  font-size: 1.2rem;
}

.courses__keyfeatures > div {
  display: flex;
  align-items: center;
  margin: 0.2rem 0 0 0;
  width: 100%;
  height: 30px;
}

.courses__keyfeatures > div > img {
  height: 15px;
}

.courses__keyfeatures > div > p {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
}

.courses__boards-container,
.courses__language-container {
  padding: 1rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.courses__boards-container p,
.courses__language-container p {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.courses__boards-container > p:nth-child(3),
.courses__language-container > p:nth-child(3) {
  font-size: 1rem;
}

.courses__boards-container ul,
.courses__language-container div {
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.courses__boards-container ul li {
  font-weight: 500;
  font-size: 0.9rem;
}

.courses__boards-container ul li:last-child {
  list-style: none;
}

.courses__language-container div > span {
  font-weight: 500;
}

.courses__board-list {
  color: #000;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 1.5px;
}

.courses__gradecontainer {
  margin: 0 auto;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1rem;
}

.courses__gradecard {
  font-weight: 500;
  font-size: 1.1rem;
  padding: 1rem 2rem;
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  box-shadow: 0 -4px 5px 0px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.courses__gradecard--active {
  background-color: var(--base-purple);
  color: #fff;
}

.courses__detail-heading {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 1rem; */
}

.courses__detail-heading div {
  width: 35%;
  border-top: 1.5px solid #d2d2d2;
}

.courses__detail-heading--language div {
  width: 32%;
  margin-top: 0;
}

.courses__content {
  padding: 2rem calc(var(--body-side-paddings) + 10rem);
}

.courses__content > div:nth-child(2) {
  border: 2px solid #d2d2d2;
  padding: 2rem 0;
  border-radius: 1rem;
}

.courses__content2 {
  padding: 2rem var(--body-side-paddings);
}

.courses__card-container{
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.courses__card-container > div > p:nth-child(1){
  height: initial;
  margin-bottom: 1rem;
}

.courses__card-container > div > a{
  height: initial;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  width: initial;
}

.courses__card-container > div > p:nth-child(3){
  font-weight: 600;
  font-size: 0.8rem;
}

.courses__subjects-container {
  display: grid !important;
  margin-top: 1rem;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.5rem;
}

.courses__subject-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
}

.courses__subject-card p {
  font-weight: 500;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 1rem;
}

.courses__subject-card p:nth-child(3){
  color: var(--base-purple);
  font-size: 0.9rem;
  font-weight: 600;
}

.courses__subject-message {
  font-size: 1rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1rem;
  width: 70%;
  font-weight: 400;
}

/* old code */

.courses__header {
  height: 46vh;
  background-image: url("https://res.cloudinary.com/learnhat/image/upload/v1630672887/website_images/page-header-bg-courses.jpg");
  background-size: cover;
  background-position: center;
}

.courses__header-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0 var(--body-side-paddings);
  padding-top: 20vh;
}

.courses__heading {
  color: #ffffff;
  font-size: 3rem;
}

.courses__card {
  border-radius: 0.5rem;
  overflow: hidden;
  width: 35%;
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 2rem;
}

.course__card-image {
  width: 100%;
  position: relative;
}

.course__card-details {
  padding: 2rem 1rem;
}

.course__for {
  font-size: 1.1rem;
  font-weight: 600;
}

.course__detail-heading {
  font-size: 1rem;
  margin-top: 0.2rem;
}

.heading--big {
  min-height: 60px;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.course__detail-subheading {
  font-size: 1rem;
  margin-top: 0.5rem;
}

.course__card-detail-subheading-desc {
  font-size: 0.8rem;
  margin-top: 0.5rem;
  height: 40px;
  display: flex;
  align-items: center;
}

.courses__price {
  font-size: 1rem;
}

.courses__card-cta {
  text-align: center;
  padding-top: 1.5rem;
  border-top: 1px solid #d2d2d2;
  width: 80%;
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
}

.courses__card-cta > p {
  color: var(--gray);
}

.badge {
  background-color: var(--base-purple);
  color: #fff;
  padding: 0.3rem 0.4rem;
  font-size: 0.8rem;
  border-radius: 0.3rem;
  letter-spacing: 1.5px;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

@media screen and (max-width: 500px) {
  .courses__features {
    padding: 1rem;
  }
  .courses__feature-heading {
    font-size: 1rem;
    text-align: center;
  }
  .courses__badges {
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .courses__keyfeatures {
    width: 100%;
    margin-top: 1rem;
  }
  .courses__keyfeatures > h2 {
    font-size: 1.2rem;
    text-align: center;
  }
  .courses__keyfeatures > div {
    display: flex;
    align-items: center;
    margin: 0.5rem 0 0 0;
    width: 100%;
    height: 50px;
  }

  .courses__content {
    padding: 1rem;
  }

  .courses__gradecontainer {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0;
  }

  .courses__gradecard {
    font-size: 0.8rem;
    padding: 1rem;
  }
  .courses__gradecard--active {
    background-color: var(--base-purple);
    color: #fff;
  }

  .courses__subject-message {
    width: 90%;
    font-weight: 400;
  }
  .courses__detail-heading div {
    width: 15%;
  }

  .courses__detail-heading--language div {
    width: 12% !important;
  }

  .courses__subjects-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .courses__subject-card{
    padding: 0.5rem;
  }

  .courses__subject-card p:nth-child(3){
  color: var(--base-purple);
  font-size: 0.8rem;
  font-weight: 600;
}

  .courses__boards-container ul,
  .courses__language-container div {
    width: 90%;
  }

  /* old code */
  .courses__header {
    height: 35vh;
  }
  .courses__header-overlay {
    padding: 1rem;
    padding-top: 20vh;
  }
  .courses__heading {
    font-size: 1.5rem;
    text-align: center;
  }
  .courses__content2{
    padding: 1rem;
  }
  .courses__card-container {
    padding: 1rem !important;
    flex-direction: column;
    margin-top: 1rem;
  }
  .courses__card-container > div{
    margin-bottom: 1rem;
  }
  .courses__card-container > div:last-child{
    margin-bottom: 0;
  }
  .courses__card {
    width: 100%;
  }
}
