.privacy{
    width: 60%;
    margin: 0 auto;
    padding: 2rem;
}

.privacy__list-heading{
    font-weight: 600;
    font-size: 1rem;
}

ul{
    margin-left: 2rem;
}

@media screen and (max-width: 500px) {
    .privacy{
        width: 100%;
        padding: 1rem;
    }
}