.metrics {
  width: 100%;
  background-color: #fff;
}

.metrics__container {
  width: 100%;
  padding: 2rem var(--body-side-paddings);
}

.metrics__display {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.metrics__cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}

@media screen and (max-width: 500px) {
  .metrics {
    height: initial;
  }
  .metrics__container {
    padding: 1rem;
  }
  .metrics__display {
    flex-direction: column;
  }
  .metrics__heading {
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
  }
  .metrics__cards {
    margin-top: 1.5rem;
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
}
