.why-learnhat {
  width: 100%;
  height: initial;
  background-color: #fff;
  padding: 1rem var(--body-side-paddings);
}

.why-learnhat__heading {
  text-align: center;
  font-size: 2.5rem;
}

.why-learnhat__cards {
  margin-top: 3.5rem;
  display: grid;
  grid-gap: 1.8rem;
  grid-template-columns: 1fr 1fr 1fr;
}

@media screen and (max-width: 500px) {
  .why-learnhat {
    padding: 1rem;
    height: initial;
    margin-top: 2rem;
  }

  .why-learnhat__heading {
    text-align: center;
    font-size: 1.5rem;
  }

  .why-learnhat__cards {
    grid-template-columns: 1fr;
  }
}
